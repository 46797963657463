import React from 'react'
import { Helmet } from 'react-helmet'
import './quotes.scss'
import { connect } from 'react-redux'
import { mapStateToProps } from '../state/mapState'
import { mapDispatchToProps } from '../state/mapDispatch'
import QuotesSection from '../components/QuotesSection/quotes-section'
import BackToTop from '../components/BackToTop/backToTop'
import Navbar from '../components/Navbar/navbar'
import { ThemeProvider } from '../components/Helpers/themes'

const Quotes = props => {
  return (
    <div className="application">
      <Helmet>
        <title>Alex Verzea's Site</title>
        <meta name="description" content="AV Site" />
        <link rel="canonical" href="https://av-site.com/quotes" />
      </Helmet>
      <ThemeProvider theme={props.theme}>
        <Navbar
          theme={props.theme}
          themes={props.themes}
          changeTheme={props.changeTheme}
        />
        <QuotesSection theme={props.theme} />
        <BackToTop theme={props.theme} />
      </ThemeProvider>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quotes)
