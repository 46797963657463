import React, { useState } from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import './quotes-section.scss'
import { MDBCard, MDBCardBody, MDBNav } from 'mdbreact'
import Img from 'gatsby-image'

const QuotesSection = props => {
  const { allContentfulQuotesWithAuthor } = useStaticQuery(
    graphql`
      query {
        allContentfulQuotesWithAuthor(
          sort: { order: DESC, fields: updatedAt }
        ) {
          edges {
            node {
              authorName
              authorTitle
              authorPicture {
                fixed {
                  ...GatsbyContentfulFixed_tracedSVG
                }
              }
              childContentfulQuotesWithAuthorQuotesJsonNode {
                internal {
                  content
                }
              }
            }
          }
        }
      }
    `
  )
  const common = allContentfulQuotesWithAuthor.edges
  const allAuthors = []
  const quotesWithAuthors = []
  common.map(entry => {
    allAuthors.push(entry.node.authorName)
    quotesWithAuthors.push({
      authorName: entry.node.authorName,
      authorTitle: entry.node.authorTitle,
      authorPicture: entry.node.authorPicture.fixed,
      authorQuotes: JSON.parse(
        entry.node.childContentfulQuotesWithAuthorQuotesJsonNode.internal
          .content
      ).quotes,
    })
  })

  const [currentAuthorIndex, setCurrentAuthorIndex] = useState(0)

  const changeAuthor = nextIndex => {
    setCurrentAuthorIndex(nextIndex)
  }

  return (
    <div
      className="quotes-block py-5"
      style={props.theme.tertiaryBackgroundColor}
    >
      <div className="container">
        <div className="row">
          <MDBNav
            tabs
            className="nav-fill"
            style={props.theme.primaryBackgroundColor}
          >
            {allAuthors.map((author, index) => (
              <li
                key={index}
                className="nav-item rounded text-white waves-effect"
              >
                <a
                  className={
                    author == allAuthors[currentAuthorIndex]
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                  rel="noopener"
                  onClick={() => changeAuthor(allAuthors.indexOf(author))}
                  target="_blank"
                >
                  {author}
                </a>
              </li>
            ))}
          </MDBNav>
        </div>
        <div className="row">
          <div className="pictures-container col-lg-4 col-12">
            <Img
              className="quotes-author rounded"
              alt="quotes-author"
              fixed={quotesWithAuthors[currentAuthorIndex].authorPicture}
            />
            <MDBCard className="quotes-author-title">
              <MDBCardBody>
                <h3 style={props.theme.primaryColor} className="title">
                  {quotesWithAuthors[currentAuthorIndex].authorName}
                </h3>
                <p className="company">
                  {quotesWithAuthors[currentAuthorIndex].authorTitle}
                </p>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="quotes-container col-lg-8 col-12">
            {quotesWithAuthors[currentAuthorIndex].authorQuotes.map(
              (quote, index) => (
                <MDBCard className="quote-card" key={index}>
                  <MDBCardBody>
                    <i
                      style={props.theme.primaryColor}
                      className="fas fa-quote-left"
                    ></i>
                    <p className="quote">{quote}</p>
                    <i
                      style={props.theme.primaryColor}
                      className="fas fa-quote-right"
                    ></i>
                  </MDBCardBody>
                </MDBCard>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(QuotesSection)
